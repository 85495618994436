var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('ul', {
    staticClass: "nav nav-tabs nav-tabs-block"
  }, [_vm._l(_vm.tabs, function (tab) {
    return _c('li', {
      key: tab,
      staticClass: "nav-item text-capitalize"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: _vm.selectedTab === tab
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function () {
          return _vm.onClickTab(tab);
        }
      }
    }, [_vm._v(_vm._s(tab))])]);
  }), _c('li', {
    staticClass: "nav-item ml-auto"
  }, [_vm.invoiceLoading.getAnalysis ? _c('span', {
    staticClass: "nav-link d-inline-block"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-spinner fa-spin"
  })]) : _vm._e(), _c('div', {
    staticClass: "dropdown d-inline-block"
  }, [_c('button', {
    staticClass: "btn-block-option font-w600 dropdown-toggle",
    attrs: {
      "type": "button",
      "data-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }
  }, [_vm._v(" " + _vm._s(_vm.template.version) + " ")]), _c('div', {
    staticClass: "dropdown-menu dropdown-menu-right"
  }, [_vm._l(_vm.templates, function (t) {
    return _c('a', {
      key: t._id,
      staticClass: "dropdown-item",
      class: {
        active: _vm.template._id === t._id
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.setTemplate(t);
          }.apply(null, arguments);
        }
      }
    }, [_vm._v(_vm._s(t.version))]);
  }), _c('div', {
    staticClass: "dropdown-divider",
    attrs: {
      "role": "separator"
    }
  }), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewTemplate.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Create New")]), _c('a', {
    staticClass: "dropdown-item",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickCloneTemplate.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-clone"
  }), _vm._v(" Duplicate")])], 2)]), _c('a', {
    staticClass: "nav-link d-inline-block",
    class: {
      disabled: _vm.invoiceLoading.getAnalysis
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickPrevPreview.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-arrow-left"
  })]), _c('a', {
    staticClass: "nav-link d-inline-block",
    class: {
      disabled: _vm.invoiceLoading.getAnalysis
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNextPreview.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-arrow-right"
  })]), _c('a', {
    staticClass: "nav-link d-inline-block",
    class: {
      disabled: _vm.invoiceLoading.getAnalysis
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickRefreshPreview.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-redo"
  })]), _c('a', {
    staticClass: "nav-link d-inline-block text-danger",
    class: {
      disabled: _vm.invoiceLoading.getAnalysis
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickResetPreview.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-times"
  })])])], 2), _vm.isLoading ? _c('div', {
    staticClass: "tab-content"
  }, [_vm._m(0)]) : _vm.noTemplate ? _c('div', {
    staticClass: "tab-content p-0 mt-4"
  }, [_vm._m(1)]) : _c('div', {
    staticClass: "tab-content p-0"
  }, [_c('div', {
    staticClass: "tab-pane py-0 px-3",
    class: {
      active: _vm.selectedTab === 'editor'
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-6 bg-white py-4"
  }, [_vm.loadingInvoice ? _c('div', [_vm._m(2)]) : _vm._e(), !_vm.currentInvoice.fileName && !_vm.loadingInvoice ? _c('div', [_vm.invoices && _vm.invoices.length > 0 ? _c('table', {
    staticClass: "table table-borderless"
  }, [_vm._m(3), _c('tbody', _vm._l(_vm.invoices, function (invoice) {
    return _c('tr', {
      key: invoice.jobId
    }, [_c('td', [_vm._v(_vm._s(invoice.fileName))]), _c('td', [_c('button', {
      staticClass: "btn btn-primary",
      on: {
        "click": function () {
          return _vm.onClickLoadInvoice(invoice);
        }
      }
    }, [_vm._v("Load")])])]);
  }), 0)]) : _c('div', {
    staticClass: "alert alert-warning mb-0",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No invoices found for this supplier.")])])]) : !_vm.loadingInvoice ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-middle mb-3"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.currentInvoice.fileName))]), _c('button', {
    staticClass: "btn btn-link text-danger",
    on: {
      "click": function () {
        return _vm.onClickUnloadInvoice(_vm.invoice);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-times"
  })])]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center px-4"
  }, [_c('ul', {
    staticClass: "pagination"
  }, [_c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.preview.currentPage === 1
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.prevPreviewPage.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-left"
  })])]), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.preview.currentPage === _vm.preview.totalPages
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.nextPreviewPage.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  })])])]), _c('div', {
    staticClass: "font-w700"
  }, [_vm._v("Page " + _vm._s(_vm.preview.currentPage) + " of " + _vm._s(_vm.preview.totalPages))])]), _c('div', {
    staticClass: "pdf-preview mb-4"
  }, [_c('div', {
    ref: "previewBox",
    staticClass: "bounds-wrapper ribbon ribbon-success ribbon-left text-uppercase",
    on: {
      "mousedown": _vm.onStartBound,
      "mouseup": _vm.onEndBound,
      "mousemove": _vm.onUpdateBound
    }
  }, [_vm.currentPageFromRules ? _c('div', {
    staticClass: "ribbon-box"
  }, [_vm._v(_vm._s(_vm.currentPageFromRules.name))]) : _vm._e(), _vm.currentPageRawLines && _vm.currentPageRawLines.length > 0 ? _c('div', {
    staticClass: "line-bounds"
  }, [_vm.selectedSubTab == 'pages' && _vm.currentPageFromRules ? _c('div', _vm._l(_vm.currentPageFromRules.linesMatched, function (line, lineIdx) {
    return _c('div', {
      key: lineIdx,
      staticClass: "line-bound line-bound--page",
      style: {
        width: line.polygon.width * 100 + 1 + '%',
        height: line.polygon.height * 100 + 0.5 + '%',
        top: line.polygon.top * 100 - 0.25 + '%',
        left: line.polygon.left * 100 - 0.5 + '%'
      },
      attrs: {
        "title": line.text
      }
    });
  }), 0) : _vm._e(), _vm.selectedSubTab == 'supplier' ? _c('div', _vm._l(_vm.currentPageSupplierRuleLines, function (line, lineIdx) {
    return _c('div', {
      key: lineIdx,
      staticClass: "line-bound line-bound--supplier",
      style: {
        width: line.polygon.width * 100 + 1 + '%',
        height: line.polygon.height * 100 + 0.5 + '%',
        top: line.polygon.top * 100 - 0.25 + '%',
        left: line.polygon.left * 100 - 0.5 + '%'
      },
      attrs: {
        "title": line.text
      }
    });
  }), 0) : _vm._e(), _vm.selectedSubTab == 'bounds' && _vm.settings.showLines ? _c('div', _vm._l(_vm.currentPageRawLines, function (line, lineIdx) {
    return _c('div', {
      key: lineIdx,
      staticClass: "line-bound",
      style: {
        width: line.polygon.width * 100 + 1 + '%',
        height: line.polygon.height * 100 + 0.5 + '%',
        top: line.polygon.top * 100 - 0.25 + '%',
        left: line.polygon.left * 100 - 0.5 + '%'
      },
      attrs: {
        "title": line.text
      }
    });
  }), 0) : _vm._e(), _vm.selectedSubTab == 'bounds' && _vm.settings.allowDrawing ? _c('div', [_c('div', {
    staticClass: "line-bound line-bound--user",
    style: {
      width: _vm.userBound.width + 'px',
      height: _vm.userBound.height + 'px',
      top: _vm.userBound.top + 'px',
      left: _vm.userBound.left + 'px'
    }
  })]) : _vm._e(), _vm.selectedSubTab == 'bounds' && _vm.settings.showBounds || _vm.selectedSubTab == 'values' ? _c('div', _vm._l(_vm.currentPageBounds.filter(function (b) {
    return b.type !== 'table';
  }), function (bound) {
    return _c('div', {
      key: bound.name,
      staticClass: "line-bound line-bound--bound",
      style: {
        width: bound.width * 100 + 1 + '%',
        height: bound.height * 100 + 0.5 + '%',
        top: bound.top * 100 - 0.25 + '%',
        left: bound.left * 100 - 0.5 + '%'
      },
      attrs: {
        "title": bound.name
      }
    }, [_c('span', {
      staticClass: "line-bound-label"
    }, [_vm._v(_vm._s(bound.name))])]);
  }), 0) : _vm._e(), _vm.selectedSubTab == 'bounds' && _vm.settings.showCells || _vm.selectedSubTab == 'values' ? _c('div', _vm._l(_vm.currentPageCells, function (cell, cellIdx) {
    return _c('div', {
      key: cellIdx,
      staticClass: "line-bound line-bound--cell",
      style: {
        width: cell.polygon.width * 100 + 1 + '%',
        height: cell.polygon.height * 100 + 0.5 + '%',
        top: cell.polygon.top * 100 - 0.25 + '%',
        left: cell.polygon.left * 100 - 0.5 + '%'
      }
    });
  }), 0) : _vm._e()]) : _vm._e(), _c('PdfViewer', {
    attrs: {
      "src": _vm.preview.url,
      "page": _vm.preview.currentPage
    }
  })], 1)])]) : _vm._e()]), _c('div', {
    staticClass: "col-xl-6 px-4"
  }, [_c('ul', {
    staticClass: "nav nav-pills nav-justified push mt-3"
  }, [_c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedSubTab === 'version'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.onClickSubTab('version');
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-clone mr-2"
  }), _vm._v(" Version")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedSubTab === 'supplier'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.onClickSubTab('supplier');
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-box mr-2"
  }), _vm._v(" Supplier")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedSubTab === 'pages'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.onClickSubTab('pages');
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-layer-group mr-2"
  }), _vm._v(" Pages")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedSubTab === 'bounds'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.onClickSubTab('bounds');
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-draw-square mr-2"
  }), _vm._v(" Bounds")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.selectedSubTab === 'values'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.onClickSubTab('values');
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-font-case mr-2"
  }), _vm._v(" Values")])])]), _c('keep-alive', [_c(_vm.subTabMap[_vm.selectedSubTab], {
    tag: "component",
    attrs: {
      "user-bound": _vm.userBound,
      "current-lines": _vm.currentPageRawLines,
      "current-cells": _vm.currentPageCells,
      "preview-box": _vm.$refs.previewBox,
      "current-page": _vm.currentPageFromRules,
      "current-invoice": _vm.currentInvoice
    },
    on: {
      "updatePreview": _vm.onRefreshTemplate
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "tab-pane bg-white p-3",
    class: {
      active: _vm.selectedTab === 'preview'
    }
  }, [_vm.currentInvoice.detailedResults ? _c('InvoiceResultsDetailed', {
    attrs: {
      "invoice": _vm.currentInvoice,
      "supplier": _vm.supplier,
      "invoice-keys": _vm.invoiceKeys
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center py-5"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-warning mb-0",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No template found for this supplier.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center py-5"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("File Name")]), _c('th')])]);

}]

export { render, staticRenderFns }