var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loading.results && _vm.invoice._id ? _c('div', {
    key: _vm.invoice._id
  }, [_c('h4', [_vm._v("Invoice Values")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('table', {
    staticClass: "table table-striped mb-4"
  }, [_c('tbody', _vm._l(_vm.invoiceValues.slice(0, Math.ceil(_vm.invoiceValues.length / 2)), function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.name))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("round")(value.friendlyValue, 2)) + " ")]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_vm._v(" " + _vm._s(_vm._f("round")(value.calculatedValue, 2)) + " ")])]);
  }), 0)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('table', {
    staticClass: "table table-striped mb-4"
  }, [_c('tbody', _vm._l(_vm.invoiceValues.slice(Math.ceil(_vm.invoiceValues.length / 2), _vm.invoiceValues.length), function (value) {
    return _c('tr', {
      key: value.key
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(value.name))]), _c('td', [_vm._v(" " + _vm._s(_vm._f("round")(value.friendlyValue, 2)) + " ")]), _c('td', {
      staticClass: "text-info font-w600"
    }, [_vm._v(" " + _vm._s(_vm._f("round")(value.calculatedValue, 2)) + " ")])]);
  }), 0)])])]), _c('div', [_c('h4', [_vm._v("Invoice Rates")]), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'unitRate';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'unitRate';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Unit Rates")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'unitRate';
      })
    }
  })], 1) : _vm._e(), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'kva';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'kva';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Availability Charges")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'kva';
      })
    }
  })], 1) : _vm._e(), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'levy';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'levy';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Levies")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'levy';
      })
    }
  })], 1) : _vm._e(), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'daily';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'daily';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Daily Charges")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'daily';
      })
    }
  })], 1) : _vm._e(), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'waterRate';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'waterRate';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Water Rates")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'waterRate';
      })
    }
  })], 1) : _vm._e(), _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'wasteRate';
  }) && _vm.invoiceRates.filter(function (rate) {
    return rate.type === 'wasteRate';
  }).length > 0 ? _c('div', [_c('h5', [_vm._v("Waste Rates")]), _c('InvoiceResultsRateTable', {
    attrs: {
      "rates": _vm.invoiceRates.filter(function (rate) {
        return rate.type === 'wasteRate';
      })
    }
  })], 1) : _vm._e()])]) : _vm.loading.results ? _c('div', {
    staticClass: "d-flex justify-content-center py-5"
  }, [_vm._m(0)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);

}]

export { render, staticRenderFns }