var render = function render(){
  var _vm$ratePreviews$new;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _vm.currentInvoice && _vm.currentInvoice.detailedResults ? _c('div', [_c('div', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mb-4"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-sm mr-3",
    on: {
      "click": _vm.onClickShowValueForm
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Value")]), _c('button', {
    staticClass: "btn btn-warning btn-sm",
    on: {
      "click": _vm.onClickShowRateForm
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" Add Rate")])]), _vm.valueForm.show ? _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content block-content-full mb-4"
  }, [_c('h5', [_vm._v("Add New Value")]), _vm.template.bounds.length > 0 ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "valueBound",
      "label": "Bound",
      "type": "select",
      "options": _vm.template.bounds.map(function (b) {
        return {
          value: b.name,
          label: b.name
        };
      })
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.bound,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "bound", $$v);
      },
      expression: "valueForm.bound"
    }
  }), _c('label', [_vm._v("Bound Preview")]), _vm.valueForm.bound && _vm.valuePreviews.new.boundPreview ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.valuePreviews.new.boundPreview, function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-0",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])]), _c('FormGroup', {
    attrs: {
      "id": "valueName",
      "label": "Name",
      "type": "text"
    },
    model: {
      value: _vm.valueForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "name", $$v);
      },
      expression: "valueForm.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "valueType",
      "label": "Type",
      "type": "select",
      "options": _vm.valueTypes.map(function (t) {
        return {
          value: t,
          label: t
        };
      })
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "type", $$v);
      },
      expression: "valueForm.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "valueKey",
      "label": "Invoice Key",
      "type": "select",
      "options": _vm.invoiceKeys.filter(function (t) {
        return t.types.includes(_vm.$route.meta.type);
      }).map(function (t) {
        return {
          value: t.key,
          label: t.friendly
        };
      })
    },
    model: {
      value: _vm.valueForm.key,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "key", $$v);
      },
      expression: "valueForm.key"
    }
  }), _vm.valueForm.type === 'date' ? _c('div', [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "valueFormat",
      "type": "text",
      "label": "Date Format"
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.format,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "format", $$v);
      },
      expression: "valueForm.format"
    }
  }), _vm._m(1)], 1) : _vm._e(), _vm.valueForm.type === 'price' ? _c('FormGroup', {
    attrs: {
      "id": "valueFormat",
      "type": "select",
      "label": "Price Format",
      "options": _vm.priceFormats.map(function (f) {
        return {
          value: f.value,
          label: f.label
        };
      })
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.format,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "format", $$v);
      },
      expression: "valueForm.format"
    }
  }) : _vm._e(), _vm.valueForm.bound && _vm.valuePreviews.new.bound.group === 'seperate' ? _c('FormGroup', {
    attrs: {
      "id": "valueLineRegex",
      "type": "text",
      "label": "Line Regex"
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.lineRegex,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "lineRegex", $$v);
      },
      expression: "valueForm.lineRegex"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "boundRegex",
      "type": "text"
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.regex,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "regex", $$v);
      },
      expression: "valueForm.regex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "form-control-alt",
    attrs: {
      "id": "boundRegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    on: {
      "input": _vm.refreshNewValuePreview
    },
    model: {
      value: _vm.valueForm.regexType,
      callback: function ($$v) {
        _vm.$set(_vm.valueForm, "regexType", $$v);
      },
      expression: "valueForm.regexType"
    }
  })], 1)], 1)]), _vm.valueForm.bound ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Preview")]), _c('div', [_vm._v(_vm._s(_vm.valuePreviews.new.valuePreview))])]) : _vm._e(), _vm.valueForm.bound ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Formatted Preview")]), _c('div', [_vm._v(_vm._s(_vm.formattedValues.new || 'N/A'))])]) : _vm._e()], 1) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No bounds on page.")])]), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading.addValue || _vm.template.bounds.length === 0
    },
    on: {
      "click": _vm.onClickCreateValue
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" " + _vm._s(_vm.loading.addValue ? 'Adding...' : 'Add Value') + " ")])])]) : _vm._e(), _vm.rateForm.show ? _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content block-content-full mb-4"
  }, [_c('h5', [_vm._v("Add New Rate")]), _vm.template.bounds.length > 0 ? _c('div', [_c('FormGroup', {
    attrs: {
      "id": "rateType",
      "label": "Type",
      "type": "select",
      "options": [{
        label: 'Unit Rate (p/kWh)',
        value: 'unitRate'
      }, {
        label: 'Daily (£/day)',
        value: 'daily'
      }, {
        label: 'Levy (p/kWh)',
        value: 'levy'
      }, {
        label: 'VAT (%)',
        value: 'tax'
      }, {
        label: 'Availability (kVA)',
        value: 'kva'
      }, {
        label: 'Water (£/m3)',
        value: 'waterRate'
      }, {
        label: 'Waste (£/m3)',
        value: 'wasteRate'
      }]
    },
    model: {
      value: _vm.rateForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "type", $$v);
      },
      expression: "rateForm.type"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "rateTypeMultiplier",
      "label": "Rate Multiplier",
      "type": "number",
      "min": "1",
      "max": "1000",
      "step": "1"
    },
    model: {
      value: _vm.rateForm.typeMultiplier,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "typeMultiplier", $$v);
      },
      expression: "rateForm.typeMultiplier"
    }
  }), _c('ul', {
    staticClass: "nav nav-pills nav-justified push"
  }, [_c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'rateName'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('rateName');
      }
    }
  }, [_vm._v("Name")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'startDate'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('startDate');
      }
    }
  }, [_vm._v("Start Date")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'endDate'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('endDate');
      }
    }
  }, [_vm._v("End Date")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'consumption'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('consumption');
      }
    }
  }, [_vm._v("Consumption")])]), _c('li', {
    staticClass: "nav-item mr-1"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'unitRate'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('unitRate');
      }
    }
  }, [_vm._v("Unit Rate")])]), _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'cost'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('cost');
      }
    }
  }, [_vm._v("Cost")])]), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'startRead'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('startRead');
      }
    }
  }, [_vm._v("S Read")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'endRead'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('endRead');
      }
    }
  }, [_vm._v("E Read")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'startReadType'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('startReadType');
      }
    }
  }, [_vm._v("S Read Type")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "nav-link",
    class: {
      active: _vm.rateForm.tab === 'endReadType'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onClickNewRateTab('endReadType');
      }
    }
  }, [_vm._v("E Read Type")])]) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rateForm.tab === 'consumption',
      expression: "rateForm.tab === 'consumption'"
    }]
  }, [_c('FormGroup', {
    attrs: {
      "id": "rateConsumptionBound",
      "label": "Bound",
      "type": "select",
      "options": _vm.template.bounds.map(function (b) {
        return {
          value: b.name,
          label: b.name
        };
      })
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.consumptionBound,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "consumptionBound", $$v);
      },
      expression: "rateForm.consumptionBound"
    }
  }), _c('label', [_vm._v("Bound Preview")]), _vm.rateForm.consumptionBound && _vm.ratePreviews.new.consumptionBoundPreview ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.ratePreviews.new.consumptionBoundPreview, function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews.new.consumptionBound.type === 'bound' && _vm.ratePreviews.new.consumptionBound.group === 'seperate' ? _c('FormGroup', {
    attrs: {
      "id": "rateLineRegex",
      "type": "text",
      "label": "Line Regex"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.consumptionLineRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "consumptionLineRegex", $$v);
      },
      expression: "rateForm.consumptionLineRegex"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "consumptionRegex",
      "type": "text"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.consumptionRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "consumptionRegex", $$v);
      },
      expression: "rateForm.consumptionRegex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "form-control-alt",
    attrs: {
      "id": "consumptionRegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.consumptionRegexType,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "consumptionRegexType", $$v);
      },
      expression: "rateForm.consumptionRegexType"
    }
  })], 1)], 1)])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rateForm.tab === 'unitRate',
      expression: "rateForm.tab === 'unitRate'"
    }]
  }, [_c('FormGroup', {
    attrs: {
      "id": "rateUnitRateBound",
      "label": "Bound",
      "type": "select",
      "options": _vm.template.bounds.map(function (b) {
        return {
          value: b.name,
          label: b.name
        };
      })
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.unitRateBound,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "unitRateBound", $$v);
      },
      expression: "rateForm.unitRateBound"
    }
  }), _c('label', [_vm._v("Bound Preview")]), _vm.rateForm.unitRateBound && _vm.ratePreviews.new.unitRateBoundPreview ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.ratePreviews.new.unitRateBoundPreview, function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews.new.unitRateBound.type === 'bound' && _vm.ratePreviews.new.unitRateBound.group === 'seperate' ? _c('FormGroup', {
    attrs: {
      "id": "rateLineRegex",
      "type": "text",
      "label": "Line Regex"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.unitRateLineRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "unitRateLineRegex", $$v);
      },
      expression: "rateForm.unitRateLineRegex"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "unitRateRegex",
      "type": "text"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.unitRateRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "unitRateRegex", $$v);
      },
      expression: "rateForm.unitRateRegex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "form-control-alt",
    attrs: {
      "id": "unitRateRegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.unitRateRegexType,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "unitRateRegexType", $$v);
      },
      expression: "rateForm.unitRateRegexType"
    }
  })], 1)], 1)])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.rateForm.tab === 'cost',
      expression: "rateForm.tab === 'cost'"
    }]
  }, [_c('FormGroup', {
    attrs: {
      "id": "rateCostBound",
      "label": "Bound",
      "type": "select",
      "options": _vm.template.bounds.map(function (b) {
        return {
          value: b.name,
          label: b.name
        };
      })
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.costBound,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "costBound", $$v);
      },
      expression: "rateForm.costBound"
    }
  }), _c('label', [_vm._v("Bound Preview")]), _vm.rateForm.costBound && _vm.ratePreviews.new.costBoundPreview ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.ratePreviews.new.costBoundPreview, function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews.new.costBound.type === 'bound' && _vm.ratePreviews.new.costBound.group === 'seperate' ? _c('FormGroup', {
    attrs: {
      "id": "rateLineRegex",
      "type": "text",
      "label": "Line Regex"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.costLineRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "costLineRegex", $$v);
      },
      expression: "rateForm.costLineRegex"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "costRegex",
      "type": "text"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.costRegex,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "costRegex", $$v);
      },
      expression: "rateForm.costRegex"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "form-control-alt",
    attrs: {
      "id": "costRegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.costRegexType,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "costRegexType", $$v);
      },
      expression: "rateForm.costRegexType"
    }
  })], 1)], 1)]), _c('FormGroup', {
    attrs: {
      "id": "rateCostFormat",
      "type": "select",
      "label": "Cost Price Format",
      "options": _vm.priceFormats.map(function (f) {
        return {
          value: f.value,
          label: f.label
        };
      })
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.costFormat,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "costFormat", $$v);
      },
      expression: "rateForm.costFormat"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(_vm.rateForm.tab),
      expression: "['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(rateForm.tab)"
    }]
  }, [_c('FormGroup', {
    attrs: {
      "id": `rate${_vm.capitalize(_vm.rateForm.tab)}Bound`,
      "label": "Bound",
      "type": "select",
      "options": _vm.template.bounds.map(function (b) {
        return {
          value: b.name,
          label: b.name
        };
      })
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm[`${_vm.rateForm.tab}Bound`],
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, `${_vm.rateForm.tab}Bound`, $$v);
      },
      expression: "rateForm[`${rateForm.tab}Bound`]"
    }
  }), _c('label', [_vm._v("Bound Preview")]), _vm.rateForm[`${_vm.rateForm.tab}Bound`] && _vm.ratePreviews.new[`${_vm.rateForm.tab}BoundPreview`] ? _c('div', [_c('table', {
    staticClass: "table table-borderless table-striped"
  }, [_c('tbody', _vm._l(_vm.ratePreviews.new[`${_vm.rateForm.tab}BoundPreview`], function (line, lineIndex) {
    return _c('tr', {
      key: lineIndex
    }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
      staticClass: "text-right align-middle"
    }, [_c('span', {
      staticClass: "badge badge-primary"
    }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
  }), 0)])]) : _c('div', {
    staticClass: "alert alert-warning mb-3",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews.new[`${_vm.rateForm.tab}Bound`].type === 'bound' && _vm.ratePreviews.new[`${_vm.rateForm.tab}Bound`].group === 'seperate' ? _c('FormGroup', {
    attrs: {
      "id": "rateLineRegex",
      "type": "text",
      "label": "Line Regex"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm[`${_vm.rateForm.tab}LineRegex`],
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, `${_vm.rateForm.tab}LineRegex`, $$v);
      },
      expression: "rateForm[`${rateForm.tab}LineRegex`]"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Regex")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "${rateForm.tab}Regex",
      "type": "text"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm[`${_vm.rateForm.tab}Regex`],
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, `${_vm.rateForm.tab}Regex`, $$v);
      },
      expression: "rateForm[`${rateForm.tab}Regex`]"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    staticClass: "form-control-alt",
    attrs: {
      "id": "${rateForm.tab}RegexType",
      "type": "select",
      "options": [{
        label: 'Capture',
        value: 'capture'
      }, {
        label: 'Replace',
        value: 'replace'
      }]
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm[`${_vm.rateForm.tab}RegexType`],
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, `${_vm.rateForm.tab}RegexType`, $$v);
      },
      expression: "rateForm[`${rateForm.tab}RegexType`]"
    }
  })], 1)], 1)]), _vm.rateForm.tab === 'startDate' ? _c('FormGroup', {
    attrs: {
      "id": "rateCostFormat",
      "type": "text",
      "label": "Date Format"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.startDateFormat,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "startDateFormat", $$v);
      },
      expression: "rateForm.startDateFormat"
    }
  }) : _vm._e(), _vm.rateForm.tab === 'endDate' ? _c('FormGroup', {
    attrs: {
      "id": "rateCostFormat",
      "type": "text",
      "label": "Date Format"
    },
    on: {
      "input": _vm.refreshNewRatePreview
    },
    model: {
      value: _vm.rateForm.endDateFormat,
      callback: function ($$v) {
        _vm.$set(_vm.rateForm, "endDateFormat", $$v);
      },
      expression: "rateForm.endDateFormat"
    }
  }) : _vm._e()], 1), _vm.rateForm.bound ? _c('div', {
    staticClass: "form-group bg-body-light"
  }, [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("End Date")]), _c('th', [_vm._v("Consumption")]), _c('th', [_vm._v("Unit Rate")]), _c('th', [_vm._v("Cost")]), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('th', [_vm._v("Start Read")]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('th', [_vm._v("End Read")]) : _vm._e()])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s((_vm$ratePreviews$new = _vm.ratePreviews.new) === null || _vm$ratePreviews$new === void 0 ? void 0 : _vm$ratePreviews$new.rateName))]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews.new.startDate) + " (" + _vm._s(_vm.formattedRateStartDates.new || 'N/A') + ")")]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews.new.endDate) + " (" + _vm._s(_vm.formattedRateEndDates.new || 'N/A') + ")")]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews.new.consumption))]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews.new.unitRate))]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews.new.cost) + " (" + _vm._s(_vm.formattedRateCosts.new || 'N/A') + ")")]), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('td', [_vm._v(" " + _vm._s(_vm.ratePreviews.new.startRead) + " (" + _vm._s(_vm.ratePreviews.new.startReadType) + ") ")]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('td', [_vm._v(" " + _vm._s(_vm.ratePreviews.new.endRead) + " (" + _vm._s(_vm.ratePreviews.new.endReadType) + ") ")]) : _vm._e()])])])]) : _vm._e()], 1) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No bounds on page.")])]), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "disabled": _vm.loading.addRate || _vm.template.bounds.length === 0
    },
    on: {
      "click": _vm.onClickCreateRate
    }
  }, [_c('i', {
    staticClass: "fa fa-fw fa-plus"
  }), _vm._v(" " + _vm._s(_vm.loading.addRate ? 'Adding...' : 'Add Rate') + " ")])])]) : _vm._e()]), _c('h4', [_vm._v("Values")]), _vm.values.length > 0 ? _c('div', _vm._l(_vm.values, function (value) {
    return _c('div', {
      key: value._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header",
      class: {
        'border-bottom': value.show
      }
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickEditValue(value._id);
          }.apply(null, arguments);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(value.name))])]), _c('span', [_vm._v(_vm._s(_vm.valuePreviews[value._id].valuePreview || 'N/A'))]), _c('span', [_c('button', {
      staticClass: "btn btn-link font-w600 mr-3",
      on: {
        "click": function () {
          return _vm.onClickEditValue(value._id);
        }
      }
    }, [_vm._v("Manage")]), _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function () {
          return _vm.onClickDeleteValue(value._id);
        }
      }
    }, [_vm._v("Delete")])])]), _c('div', {
      staticClass: "collapse",
      class: {
        show: value.show
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_vm.template.bounds.length > 0 ? _c('div', [_c('FormGroup', {
      attrs: {
        "id": `valueBound-${value._id}`,
        "label": "Bound",
        "type": "select",
        "options": _vm.template.bounds.map(function (b) {
          return {
            value: b.name,
            label: b.name
          };
        })
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.bound,
        callback: function ($$v) {
          _vm.$set(value, "bound", $$v);
        },
        expression: "value.bound"
      }
    }), _c('label', [_vm._v("Bound Preview")]), value.bound && _vm.valuePreviews[value._id].boundPreview ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.valuePreviews[value._id].boundPreview, function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-0",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])]), _c('FormGroup', {
      attrs: {
        "id": `valueName-${value._id}`,
        "label": "Name",
        "type": "text"
      },
      model: {
        value: value.name,
        callback: function ($$v) {
          _vm.$set(value, "name", $$v);
        },
        expression: "value.name"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": `valueType-${value._id}`,
        "label": "Type",
        "type": "select",
        "options": _vm.valueTypes.map(function (t) {
          return {
            value: t,
            label: t
          };
        })
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.type,
        callback: function ($$v) {
          _vm.$set(value, "type", $$v);
        },
        expression: "value.type"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": `valueKey-${value._id}`,
        "label": "Invoice Key",
        "type": "select",
        "options": _vm.invoiceKeys.filter(function (t) {
          return t.types.includes(_vm.$route.meta.type);
        }).map(function (t) {
          return {
            value: t.key,
            label: t.friendly
          };
        })
      },
      model: {
        value: value.key,
        callback: function ($$v) {
          _vm.$set(value, "key", $$v);
        },
        expression: "value.key"
      }
    }), value.type === 'date' ? _c('FormGroup', {
      attrs: {
        "id": `valueFormat-${value._id}`,
        "type": "text",
        "label": "Date Format"
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.format,
        callback: function ($$v) {
          _vm.$set(value, "format", $$v);
        },
        expression: "value.format"
      }
    }) : _vm._e(), value.type === 'date' ? _c('div', [_c('FormGroup', {
      staticClass: "mb-0",
      attrs: {
        "id": "valueFormat",
        "type": "text",
        "label": "Date Format"
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.format,
        callback: function ($$v) {
          _vm.$set(value, "format", $$v);
        },
        expression: "value.format"
      }
    }), _vm._m(2, true)], 1) : _vm._e(), value.type === 'price' ? _c('FormGroup', {
      attrs: {
        "id": `valueFormat-${value._id}`,
        "type": "select",
        "label": "Price Format",
        "options": _vm.priceFormats.map(function (f) {
          return {
            value: f.value,
            label: f.label
          };
        })
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.format,
        callback: function ($$v) {
          _vm.$set(value, "format", $$v);
        },
        expression: "value.format"
      }
    }) : _vm._e(), _c('FormGroup', {
      attrs: {
        "id": `valueLineRegex-${value._id}`,
        "type": "text",
        "label": "Line Regex"
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.lineRegex,
        callback: function ($$v) {
          _vm.$set(value, "lineRegex", $$v);
        },
        expression: "value.lineRegex"
      }
    }), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `valueRegex-${value._id}`,
        "type": "text"
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.regex,
        callback: function ($$v) {
          _vm.$set(value, "regex", $$v);
        },
        expression: "value.regex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "form-control-alt",
      attrs: {
        "id": `valueRegexType-${value._id}`,
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      on: {
        "input": function () {
          return _vm.refreshValuePreviews(value);
        }
      },
      model: {
        value: value.regexType,
        callback: function ($$v) {
          _vm.$set(value, "regexType", $$v);
        },
        expression: "value.regexType"
      }
    })], 1)], 1)]), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Preview")]), _c('div', [_vm._v(_vm._s(_vm.valuePreviews[value._id].valuePreview || 'N/A'))])]), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Formatted Preview")]), _c('div', [_vm._v(_vm._s(_vm.formattedValues[value._id] || 'N/A'))])])], 1) : _c('div', {
      staticClass: "alert alert-warning",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("Value's bounds not on current page.")])]), _c('button', {
      staticClass: "btn btn-primary btn-block mb-4",
      attrs: {
        "disabled": _vm.loading.updateValue
      },
      on: {
        "click": function () {
          return _vm.onClickSaveValue(value);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-save"
    }), _vm._v(" " + _vm._s(_vm.loading.updateValue ? 'Saving...' : 'Save Value') + " ")])])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No values found for selected bound.")])]), _c('h4', [_vm._v("Rates")]), _vm.rates.length > 0 ? _c('div', _vm._l(_vm.rates, function (rate) {
    var _vm$ratePreviews$rate, _vm$ratePreviews$rate2, _vm$ratePreviews$rate3, _vm$ratePreviews$rate4;
    return _c('div', {
      key: rate._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header",
      class: {
        'border-bottom': rate.show
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-grow-1"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between pb-2"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickEditRate(rate._id);
          }.apply(null, arguments);
        }
      }
    }, [_c('strong', [_vm._v(_vm._s(((_vm$ratePreviews$rate = _vm.ratePreviews[rate._id]) === null || _vm$ratePreviews$rate === void 0 ? void 0 : _vm$ratePreviews$rate.rateName) || 'N/A') + " ")]), (_vm$ratePreviews$rate2 = _vm.ratePreviews[rate._id]) !== null && _vm$ratePreviews$rate2 !== void 0 && _vm$ratePreviews$rate2.bound ? _c('span', {
      staticClass: "label label-info ml-2"
    }, [_vm._v(_vm._s(_vm.ratePreviews[rate._id].bound.page))]) : _vm._e()]), _c('span', [_c('button', {
      staticClass: "btn btn-link font-w600 mr-3",
      on: {
        "click": function () {
          return _vm.onClickEditRate(rate._id);
        }
      }
    }, [_vm._v("Manage")]), _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      on: {
        "click": function () {
          return _vm.onClickDeleteRate(rate._id);
        }
      }
    }, [_vm._v("Delete")])])]), _c('div', {
      staticClass: "d-flex justify-content-between border-top pt-2"
    }, [_c('span', [_c('strong', [_vm._v("S")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].startDate || 'N/A') + " ")]), _c('span', [_c('strong', [_vm._v("E")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].endDate || 'N/A') + " ")]), _c('span', [_c('strong', [_vm._v("C")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].consumption || 'N/A') + " ")]), _c('span', [_c('strong', [_vm._v("U")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].unitRate || 'N/A') + " ")]), _c('span', [_c('strong', [_vm._v("£")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].cost || 'N/A'))]), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('span', [_c('strong', [_vm._v("SR")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].startRead || 'N/A') + " (" + _vm._s(_vm.ratePreviews[rate._id].startReadType || 'N/A') + ")")]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(_vm.rateForm.type) ? _c('span', [_c('strong', [_vm._v("ER")]), _vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].endRead || 'N/A') + " (" + _vm._s(_vm.ratePreviews[rate._id].endReadType || 'N/A') + ")")]) : _vm._e()])])]), _c('div', {
      staticClass: "collapse",
      class: {
        show: rate.show
      }
    }, [_c('div', {
      staticClass: "block-content block-content-full"
    }, [_vm.template.bounds.length > 0 ? _c('div', [_c('FormGroup', {
      attrs: {
        "id": `rateType-${rate._id}`,
        "label": "Type",
        "type": "select",
        "options": [{
          label: 'Unit Rate (p/kWh)',
          value: 'unitRate'
        }, {
          label: 'Daily (£/day)',
          value: 'daily'
        }, {
          label: 'Levy (p/kWh)',
          value: 'levy'
        }, {
          label: 'VAT (%)',
          value: 'tax'
        }, {
          label: 'Availability (kVA)',
          value: 'kva'
        }, {
          label: 'Water (£/m3)',
          value: 'waterRate'
        }, {
          label: 'Waste (£/m3)',
          value: 'wasteRate'
        }]
      },
      model: {
        value: rate.type,
        callback: function ($$v) {
          _vm.$set(rate, "type", $$v);
        },
        expression: "rate.type"
      }
    }), _c('FormGroup', {
      attrs: {
        "id": `rateTypeMultiplier-${rate._id}`,
        "label": "Rate Multiplier",
        "type": "number",
        "min": "1",
        "max": "1000",
        "step": "1"
      },
      model: {
        value: rate.typeMultiplier,
        callback: function ($$v) {
          _vm.$set(rate, "typeMultiplier", $$v);
        },
        expression: "rate.typeMultiplier"
      }
    }), _c('ul', {
      staticClass: "nav nav-pills nav-justified push"
    }, [_c('li', {
      staticClass: "nav-item mr-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'rateName'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'rateName');
        }
      }
    }, [_vm._v("Name")])]), _c('li', {
      staticClass: "nav-item mr-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'startDate'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'startDate');
        }
      }
    }, [_vm._v("Start Date")])]), _c('li', {
      staticClass: "nav-item mr-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'endDate'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'endDate');
        }
      }
    }, [_vm._v("End Date")])]), _c('li', {
      staticClass: "nav-item mr-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'consumption'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'consumption');
        }
      }
    }, [_vm._v("Consumption")])]), _c('li', {
      staticClass: "nav-item mr-1"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'unitRate'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'unitRate');
        }
      }
    }, [_vm._v("Unit Rate")])]), _c('li', {
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'cost'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'cost');
        }
      }
    }, [_vm._v("Cost")])]), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('li', {
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'startRead'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'startRead');
        }
      }
    }, [_vm._v("S Read")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('li', {
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'endRead'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'endRead');
        }
      }
    }, [_vm._v("E Read")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('li', {
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'startReadType'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'startReadType');
        }
      }
    }, [_vm._v("S Read Type")])]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('li', {
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: rate.tab === 'endReadType'
      },
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickRateTab(rate._id, 'endReadType');
        }
      }
    }, [_vm._v("E Read Type")])]) : _vm._e()]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rate.tab === 'consumption',
        expression: "rate.tab === 'consumption'"
      }]
    }, [_c('FormGroup', {
      attrs: {
        "id": `rateConsumptionBound-${rate._id}`,
        "label": "Bound",
        "type": "select",
        "options": _vm.template.bounds.map(function (b) {
          return {
            value: b.name,
            label: b.name
          };
        })
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.consumptionBound,
        callback: function ($$v) {
          _vm.$set(rate, "consumptionBound", $$v);
        },
        expression: "rate.consumptionBound"
      }
    }), _c('label', [_vm._v("Bound Preview")]), rate.consumptionBound && _vm.ratePreviews[rate._id].consumptionBoundPreview ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.ratePreviews[rate._id].consumptionBoundPreview, function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-3",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews[rate._id].consumptionBound.type === 'bound' && _vm.ratePreviews[rate._id].consumptionBound.group === 'seperate' ? _c('FormGroup', {
      attrs: {
        "id": `rateConsumptionLineRegex-${rate._id}`,
        "type": "text",
        "label": "Line Regex"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.consumptionLineRegex,
        callback: function ($$v) {
          _vm.$set(rate, "consumptionLineRegex", $$v);
        },
        expression: "rate.consumptionLineRegex"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `rateConsumptionRegex-${rate._id}`,
        "type": "text"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.consumptionRegex,
        callback: function ($$v) {
          _vm.$set(rate, "consumptionRegex", $$v);
        },
        expression: "rate.consumptionRegex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "form-control-alt",
      attrs: {
        "id": `rateConsumptionRegexType-${rate._id}`,
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.consumptionRegexType,
        callback: function ($$v) {
          _vm.$set(rate, "consumptionRegexType", $$v);
        },
        expression: "rate.consumptionRegexType"
      }
    })], 1)], 1)])], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rate.tab === 'unitRate',
        expression: "rate.tab === 'unitRate'"
      }]
    }, [_c('FormGroup', {
      attrs: {
        "id": `rateUnitRateBound-${rate._id}`,
        "label": "Bound",
        "type": "select",
        "options": _vm.template.bounds.map(function (b) {
          return {
            value: b.name,
            label: b.name
          };
        })
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.unitRateBound,
        callback: function ($$v) {
          _vm.$set(rate, "unitRateBound", $$v);
        },
        expression: "rate.unitRateBound"
      }
    }), _c('label', [_vm._v("Bound Preview")]), rate.unitRateBound && _vm.ratePreviews[rate._id].unitRateBoundPreview ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.ratePreviews[rate._id].unitRateBoundPreview, function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-3",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews[rate._id].unitRateBound.type === 'bound' && _vm.ratePreviews[rate._id].unitRateBound.group === 'seperate' ? _c('FormGroup', {
      attrs: {
        "id": `rateUnitRateLineRegex-${rate._id}`,
        "type": "text",
        "label": "Line Regex"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.unitRateLineRegex,
        callback: function ($$v) {
          _vm.$set(rate, "unitRateLineRegex", $$v);
        },
        expression: "rate.unitRateLineRegex"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `rateUnitRateRegex-${rate._id}`,
        "type": "text"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.unitRateRegex,
        callback: function ($$v) {
          _vm.$set(rate, "unitRateRegex", $$v);
        },
        expression: "rate.unitRateRegex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "form-control-alt",
      attrs: {
        "id": `rateUnitRateRegexType-${rate._id}`,
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.unitRateRegexType,
        callback: function ($$v) {
          _vm.$set(rate, "unitRateRegexType", $$v);
        },
        expression: "rate.unitRateRegexType"
      }
    })], 1)], 1)])], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: rate.tab === 'cost',
        expression: "rate.tab === 'cost'"
      }]
    }, [_c('FormGroup', {
      attrs: {
        "id": `rateCostBound-${rate._id}`,
        "label": "Bound",
        "type": "select",
        "options": _vm.template.bounds.map(function (b) {
          return {
            value: b.name,
            label: b.name
          };
        })
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.costBound,
        callback: function ($$v) {
          _vm.$set(rate, "costBound", $$v);
        },
        expression: "rate.costBound"
      }
    }), _c('label', [_vm._v("Bound Preview")]), rate.costBound && _vm.ratePreviews[rate._id].costBoundPreview ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.ratePreviews[rate._id].costBoundPreview, function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-3",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews[rate._id].costBound.type === 'bound' && _vm.ratePreviews[rate._id].costBound.group === 'seperate' ? _c('FormGroup', {
      attrs: {
        "id": `rateCostLineRegex-${rate._id}`,
        "type": "text",
        "label": "Line Regex"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.costLineRegex,
        callback: function ($$v) {
          _vm.$set(rate, "costLineRegex", $$v);
        },
        expression: "rate.costLineRegex"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `rateCostRegex-${rate._id}`,
        "type": "text"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.costRegex,
        callback: function ($$v) {
          _vm.$set(rate, "costRegex", $$v);
        },
        expression: "rate.costRegex"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "form-control-alt",
      attrs: {
        "id": `rateCostRegexType-${rate._id}`,
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.costRegexType,
        callback: function ($$v) {
          _vm.$set(rate, "costRegexType", $$v);
        },
        expression: "rate.costRegexType"
      }
    })], 1)], 1)]), _c('FormGroup', {
      attrs: {
        "id": `rateCostFormat-${rate._id}`,
        "type": "select",
        "label": "Cost Price Format",
        "options": _vm.priceFormats.map(function (f) {
          return {
            value: f.value,
            label: f.label
          };
        })
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.costFormat,
        callback: function ($$v) {
          _vm.$set(rate, "costFormat", $$v);
        },
        expression: "rate.costFormat"
      }
    })], 1), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: ['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(rate.tab),
        expression: "['startRead', 'endRead', 'startReadType', 'endReadType', 'rateName', 'startDate', 'endDate'].includes(rate.tab)"
      }]
    }, [_c('FormGroup', {
      attrs: {
        "id": `rate${_vm.capitalize(rate.tab)}Bound-${rate._id}`,
        "label": "Bound",
        "type": "select",
        "options": _vm.template.bounds.map(function (b) {
          return {
            value: b.name,
            label: b.name
          };
        })
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate[`${rate.tab}Bound`],
        callback: function ($$v) {
          _vm.$set(rate, `${rate.tab}Bound`, $$v);
        },
        expression: "rate[`${rate.tab}Bound`]"
      }
    }), _c('label', [_vm._v("Bound Preview")]), rate[`${rate.tab}Bound`] && _vm.ratePreviews[rate._id][`${rate.tab}BoundPreview`] ? _c('div', [_c('table', {
      staticClass: "table table-borderless table-striped"
    }, [_c('tbody', _vm._l(_vm.ratePreviews[rate._id][`${rate.tab}BoundPreview`], function (line, lineIndex) {
      return _c('tr', {
        key: lineIndex
      }, [_c('td', [_vm._v(_vm._s(line.text))]), _c('td', {
        staticClass: "text-right align-middle"
      }, [_c('span', {
        staticClass: "badge badge-primary"
      }, [_vm._v(_vm._s(parseFloat(line.confidence).toFixed(2)) + "%")])])]);
    }), 0)])]) : _c('div', {
      staticClass: "alert alert-warning mb-3",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("No lines within bounds.")])]), _vm.ratePreviews[rate._id][`${rate.tab}Bound`].type === 'bound' && _vm.ratePreviews[rate._id][`${rate.tab}Bound`].group === 'seperate' ? _c('FormGroup', {
      attrs: {
        "id": `rateStartReadLineRegex-${rate._id}`,
        "type": "text",
        "label": "Line Regex"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate[`${rate.tab}LineRegex`],
        callback: function ($$v) {
          _vm.$set(rate, `${rate.tab}LineRegex`, $$v);
        },
        expression: "rate[`${rate.tab}LineRegex`]"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "form-group"
    }, [_c('label', [_vm._v("Regex")]), _c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      attrs: {
        "id": `rateStartReadRegex-${rate._id}`,
        "type": "text"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate[`${rate.tab}Regex`],
        callback: function ($$v) {
          _vm.$set(rate, `${rate.tab}Regex`, $$v);
        },
        expression: "rate[`${rate.tab}Regex`]"
      }
    }), _c('div', {
      staticClass: "input-group-append"
    }, [_c('FormItem', {
      staticClass: "form-control-alt",
      attrs: {
        "id": `rateStartReadRegexType-${rate._id}`,
        "type": "select",
        "options": [{
          label: 'Capture',
          value: 'capture'
        }, {
          label: 'Replace',
          value: 'replace'
        }]
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate[`${rate.tab}RegexType`],
        callback: function ($$v) {
          _vm.$set(rate, `${rate.tab}RegexType`, $$v);
        },
        expression: "rate[`${rate.tab}RegexType`]"
      }
    })], 1)], 1)]), rate.tab === 'startDate' ? _c('FormGroup', {
      attrs: {
        "id": `rateStartDateFormat-${rate._id}`,
        "type": "text",
        "label": "Date Format"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.startDateFormat,
        callback: function ($$v) {
          _vm.$set(rate, "startDateFormat", $$v);
        },
        expression: "rate.startDateFormat"
      }
    }) : _vm._e(), rate.tab === 'endDate' ? _c('FormGroup', {
      attrs: {
        "id": `rateEndDateFormat-${rate._id}`,
        "type": "text",
        "label": "Date Format"
      },
      on: {
        "input": function ($event) {
          return _vm.refreshRatePreviews(rate);
        }
      },
      model: {
        value: rate.endDateFormat,
        callback: function ($$v) {
          _vm.$set(rate, "endDateFormat", $$v);
        },
        expression: "rate.endDateFormat"
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "form-group bg-body-light"
    }, [_c('table', {
      staticClass: "table table-borderless"
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Start Date")]), _c('th', [_vm._v("End Date")]), _c('th', [_vm._v("Consumption")]), _c('th', [_vm._v("Unit Rate")]), _c('th', [_vm._v("Cost")]), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('th', [_vm._v("Sart Read")]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('th', [_vm._v("End Read")]) : _vm._e()])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s((_vm$ratePreviews$rate3 = _vm.ratePreviews[rate._id]) === null || _vm$ratePreviews$rate3 === void 0 ? void 0 : _vm$ratePreviews$rate3.rateName))]), _c('td', [_vm._v(_vm._s((_vm$ratePreviews$rate4 = _vm.ratePreviews[rate._id]) === null || _vm$ratePreviews$rate4 === void 0 ? void 0 : _vm$ratePreviews$rate4.startDate)), _c('br'), _vm._v("(" + _vm._s(_vm.formattedRateStartDates[rate._id] || 'N/A') + ")")]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews[rate._id].endDate)), _c('br'), _vm._v("(" + _vm._s(_vm.formattedRateEndDates[rate._id] || 'N/A') + ")")]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews[rate._id].consumption))]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews[rate._id].unitRate))]), _c('td', [_vm._v(_vm._s(_vm.ratePreviews[rate._id].cost) + " (" + _vm._s(_vm.formattedRateCosts[rate._id] || 'N/A') + ")")]), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('td', [_vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].startRead) + " (" + _vm._s(_vm.ratePreviews[rate._id].startReadType) + ") ")]) : _vm._e(), ['unitRate', 'waterRate', 'wasteRate'].includes(rate.type) ? _c('td', [_vm._v(" " + _vm._s(_vm.ratePreviews[rate._id].endRead) + " (" + _vm._s(_vm.ratePreviews[rate._id].endReadType) + ") ")]) : _vm._e()])])])])], 1) : _c('div', {
      staticClass: "alert alert-warning",
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v("Rate's bounds not on current page.")])]), _c('button', {
      staticClass: "btn btn-primary btn-block mb-4",
      attrs: {
        "disabled": _vm.loading.updateRate
      },
      on: {
        "click": function () {
          return _vm.onClickSaveRate(rate);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-save"
    }), _vm._v(" " + _vm._s(_vm.loading.updateRate ? 'Saving...' : 'Save Rate') + " ")])])])]);
  }), 0) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No rates found for selected bound.")])])]) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No invoice loaded.")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block block-rounded"
  }, [_c('div', {
    staticClass: "block-content mb-4"
  }, [_c('h4', [_vm._v("Value Classification")]), _c('p', [_vm._v("Pick out final values from within values.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3"
  }, [_c('small', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://momentjs.com/docs/#/displaying/format/"
    }
  }, [_vm._v("Format syntax")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-3"
  }, [_c('small', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://momentjs.com/docs/#/displaying/format/"
    }
  }, [_vm._v("Format syntax")])])]);

}]

export { render, staticRenderFns }